<template>
  <div class="main">
    <Title></Title>
    <div class="topBgc">
        <div class="topTextBox c">
          <span>关于我们</span>
        </div>
      </div>
    <div class="content c">
      <div class="companyProfile">
        <div class="title">
          <span>COMPANY INTRODUCTION</span>
          <span>公司简介</span>
          <span></span>
        </div>
        <div class="textBox">
          <img src="@/assets/img/2.jpg" alt="">
          <div class="orgRemark" v-html="jobOrg.remark1"></div>
                   <!-- 湖北楚天科创科技投资发展有限公司为武汉市硚口国有资产经营有限公司的全资子公司，以服务于区内重大项目、科创园区为宗旨，以园区运营、企业链管理、企业“一站式”服务、数据云平台等具体业务为抓手，努力发展为专业园区运营商和配套服务供应商。园区作为区
                   域内国有企业，将充分利用自身优势、为园区入驻企业（项目）提供包括企业入驻、项目落地、政策申报、金融扶持、成果转化、媒体宣传、人才安居等全方位的园区配套服务。湖北楚天科创科技投资发展有限公司为武汉市硚口国有资产经营有限公司的全资子公司，以服务于区内重大项目、科创园区为宗旨，以园区运营、企业链管理、企业
                   “一站式”服务、数据云平台等具体业务为抓手，努力发展为专业园区运营商和配套服务供应商。园区作为区域内国有企业，将充分利用自身优势、为园区入驻企业（项目）提供包括企业入驻、项目落地、政策申报、金融扶持、成果转化、媒体宣传、人才安居等全方位的园区配套服务。湖北楚天科创科技投资发展有限公司为武汉市硚口国有资产
                   经营有限公司的全资子公司，以服务于区内重大项目、科创园区为宗旨，以园区运营、企业链管理、企业“一站式”服务、数据云平台等具体业务为抓手，努力发展为专业园区运营商和配套服务供应商。园区作为区域内国有企业，将充分利用自身优势、为园区入驻企业（项目）提供包括企业入驻、项目落地、政策申报、金融扶持、成果转化、媒体宣传、人
                   才安居等全方位的园区配套服务。湖北楚天科创科技投资发展有限公司为武汉市硚口国有资产经营有限公司的全资子公司，以服务于区内重大项目、科创园区为宗旨，以园区运营、企业链管理、企业“一站式”服务、数据云平台等具体业务为抓手，努力发展为专业园区运营商和配套服务供应商。园区作为区域内国有企业，将充分利用自身优势、为园区入驻企业（项目）提供包括企业入
                   驻、项目落地、政策申报、金融扶持、成果转化、媒体宣传、人才安居等全方位的园区配套服务。湖北楚天科创科技投资发展有限公司为武汉市硚口国有资产经营有限公司的全资子公司，以服务于区内重大项目、科创园区为宗旨，以园区运营、企业链管理、企业“一站式”服务、数据云平台等具体业务为抓手，努力发展为专业园区运营商和配套服务供应商。园
                   区作为区域内国有企业，将充分利用自身优势、为园区入驻企业（项目）提供包括企业入驻、项目落地、政策申报、金融扶持、成果转化、媒体宣传、人才安居等全方位的园区配套服务。湖北楚天科创科技投资发展有限公司为武汉市硚口国有资产经营有限公司的全资子公司，以服务于区内重大项目、科创园区为宗旨，以园区运营、企业链管理、企业“一站式”服务
                   、数据云平台等具体业务为抓手，努力发展为专业园区运营商和配套服务供应商。园区作为区域内国有企业，将充分利用自身优势、为园区入驻企业（项目）提供包括企业入驻、项目落地、政策申报、金融扶持、成果转化、媒体宣传、人才安居等全方位的园区配套服务。湖北楚天科创科技投资发展有限公司为武汉市硚口国有资产经营有限公司的全资子公司，以服务于区
                   内重大项目、科创园区为宗旨，以园区运营、企业链管理、
                   企业“一站式”服务、数据云平台等具体业务为抓手，努力发展为专业园区运营商和配套服务供应商。园区作为区域内国有企业，将充分利用自身优势、为园区入驻企业（项目）提供包括企业入驻、项目落地、政策申报、金融扶持、成果转化、媒体宣传、人才安居等全方位的园区配套服务。 -->

        </div>
      </div>
      <div class="enterprisePhilosophy">
        <div class="title">
          <span>CORPORATE PHILOSOPHY</span>
          <span>企业理念</span>
          <span></span>
        </div>
        <div class="textBox orgRemark" v-html="jobOrg.remark2">
                   <!-- 共建共创共享共赢。以楚天科创为主，与相关关联方合作为辅，全面导入项目、赛事、专家、人才大数据等核心资源，共建区域双创高地，共推创业要素云集，实现与创业者资源共享，与区域发展共赢。开办以来关注时下热点，针对创业者最关心的领域，开展特色鲜明，定期举办切中需求的各类创业服务活动，累计举办活动77场，其中“汉江湾楚天智汇科创项目路演活动”7期，汉江湾科创大讲坛18场，全力打造了“科创汉江湾”公共科创品牌；导入全国集成电路“创业之芯”大赛武汉分站赛连续2届；助力入驻企业培训、沙龙等活动42场；A轮学堂项目路演8场；导入科技成果项目展示200余个、线上线下大学生培训与就业服务累计7000余次、收集和展示区域内人才供需信息10万余条，以上述“百千万”工作为抓手，全面支撑“科创汉江湾”人才体系构建和人才工程实施的相关工作。
         2021年4月—2022年4月期间，基地举办活动21场，其中科创大讲坛1场，专题讲座9场，A轮秀项目路演11场。专家丰富的经验分享以及对最新热点针对性强的专题讲座，帮助40余家企业开阔思路，解决企业当下面临的各种问题；项目路演中专家的犀利点评，中肯的建议，累计帮助50多个项目团队完善项目，加速落地。目前已有一家路演项目武汉无忧车网络科技有限公司落户楚天智汇创业孵化生态基地。截止目前园区已导入优质项目480余个，其中获奖项目50余个。园区合作伙伴汉理资本社会投资总计80家，其中完成上市融资16家，上年度投资项目5个，上市项目3个。 -->
        </div>
      </div>
      <div class="enterpriseCharacteristics">
        <div class="title">
          <span>MAIN BUSINESS</span>
          <span>企业特色</span>
          <span></span>
        </div>
        <div class="textBox">
          <p class="characteristics">基地位于楚天创新园大厦的10-12层，是以创新与创业、线上与线下、孵化与投资为主的创业平台，大厦1-8层为政务服务中心及其后台服务区，让入住企业可以享受得天独厚的便捷政务服务。大厦功能划分可简称为“下政上企”，这样上下设置的寓意也是希望以优质政务服务为基础，夯实人才成长沃土，孕育企业发展腾飞。</p>
          <div class="circularBox">
            <div class="bigCir bc1">专家</div>
            <div class="bigCir bc2">培训</div>
            <div class="bigCir bc3">人才</div>
            <div class="bigCir bc4">赛事</div>
            <div class="bigCir bc5">项目</div>
            <div class="bigCir bc6">资本</div>
            <div class="smallCir sc1" style="width:18px;height:18px"></div>
            <div class="smallCir sc2"></div>
            <div class="smallCir sc3"></div>
            <div class="smallCir sc4" style="width:16px;height:16px"></div>
            <div class="smallCir sc5"></div>
            <div class="smallCir sc6" style="width:14px;height:14px"></div>
            <div class="textBox">
              <span>人才资源优势集聚资源聚集</span>
              <div class="orgRemark1" v-html="jobOrg.remark3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="qualification">
        <div class="title">
          <span>HONORARY OUALIFICATION</span>
          <span>资质荣誉</span>
          <span></span>
        </div>
        <div class="imgBox">
          <div class="imgs" v-for="(item,index) in jobOrg.files" :key="index">
            <img :src="item" :onerror="$global.srcImgUrl" alt="">
          </div>
        </div>
      </div>
      <div class="contactUs">
        <div class="title">
          <span>CONTACT US</span>
          <span>联系我们</span>
          <span></span>
        </div>
        <div class="textBox">
          <div class="address">
            <div class="icon">
              <i class="iconfont icon-31dingwei" style="font-size:30px"></i>
            </div>
            <span>地址：{{jobOrg.address}}</span>
          </div>
          <div class="tel">
            <div class="icon">
              <i class="iconfont icon-dianhua" style="font-size:26px"></i>
            </div>
            <span>电话:{{jobOrg.tel}}</span>
          </div>
        </div>
        <div class="mapBox" v-if="jobOrg.position">
          <el-amap class="amap-box" :vid="'amap-vue'" :center="jobOrg.position">
             <el-amap-marker :position="jobOrg.position"></el-amap-marker>
             <el-amap-text :text="jobOrg.address" :offset = offset ></el-amap-text>
          </el-amap>
        </div>
      </div>
    </div>
    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
import Title from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/foot/foot.vue'
import {jobOrgDetail} from '@/api/index/index'
export default {
  name: '',
  data() {
    return {
      jobOrg: {},
      offset :[0,-50],
    }
  },
  watch: {},
  props: {},
  components: {
    Title,
    Footer,
  },
  computed: {},
  created() {
    this.getJobOrgDetail()
  },
  mounted() {},
  methods: {
    getJobOrgDetail() {
      jobOrgDetail({id:1}).then(res => {
        this.jobOrg = res.data
        this.jobOrg.files = this.jobOrg.files.split(',')
        this.jobOrg.position = [this.jobOrg.longitude,this.jobOrg.latitude]
      })
    }
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.orgRemark{
  line-height: 35px;
}
.orgRemark1{
  line-height: 30px;
}
.c {
  width: 1200px;
  margin: 0 auto;
}

p {
  margin: 0;padding: 0;
  font-size: 16px !important;
}

.topBgc {
  margin-top: 80px;
  height: 220px;
  background: url('~@/assets/img/bgc.png');
  background-repeat: no-repeat;
  background-size: 100%;
  .topTextBox {
    height: 220px;
    line-height: 220px;
    span {
      font-size: 40px;
      color: #fff;
      position: relative;
    }
    span::before {
      position: absolute;
      content: 'ABOUT';
      font-size: 76px;
      font-weight: bolder;
      color: #fff;
      left: 0;
      top: -120px;
      opacity: .4;
    }
    span::after {
      position: absolute;
      width: 100px;
      height: 2px;
      background-color: #fff;
      content: '';
      left: 0;
      top: 60px;
    }
  }
}

.content {
  background-color: #fff;
  margin-top: 40px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  span:nth-child(1) {
    font-size: 32px;
    font-weight: bolder;
    color: #999;
    opacity: .1;
    margin-bottom: -24px;
  }
  span:nth-child(2) {
    font-size: 32px;
    color: #363636;
    font-weight: bolder;
    position: relative;
  }
  span:nth-child(3) {
    width: 110px;
    height: 4px;
    background-color: #36AEFF;
    margin-top: 14px;
  }
}

.companyProfile {
  padding: 60px 90px;
  .textBox {
    min-height: 600px;
    img {
      float: left;
      width: 426px;
      height: 584px;
      margin-right: 60px;
      margin-bottom: 60px;
      border-radius: 3px;
    }
  }
}

.enterprisePhilosophy {
  padding: 60px 120px;
  min-height: 300px;
  background-color: #F5FBFF;
}

.enterpriseCharacteristics {
  padding: 60px 150px;
  .textBox {
    .characteristics {
      margin: 0;padding: 0;
      font-size: 14px;
      color: #000000;
      text-align: center;
      text-indent: 2em;
      line-height: 30px;
    }
    .circularBox {
      min-height: 500px;
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 100px;
      .bigCir {
        position: absolute;
        width: 120px;
        height: 120px;
        text-align: center;
        line-height: 120px;
        font-size: 24px;
        color: #0860CC;
        background-color: #A7D9FC;
        border-radius: 50%;
      }
      .smallCir {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #A7D9FC;
      }
      .bc1 {
        left: 36px;
        top: 0;
      }
      .bc2 {
        right: 36px;
        top: 0;
      }
      .bc3 {
        left: 0;
        top: 50%;
      }
      .bc4 {
        right: 0;
        top: 50%;
      }
      .bc5 {
        bottom: 0;
        left: 28%;
      }
      .bc6 {
        bottom: 0;
        right: 28%;
      }
      .sc1 {
        top: 28px;
        left: 30%;
      }
      .sc2 {
        top: 35%;
        left: 30px;
      }
      .sc3 {
        top: 35%;
        right: 12px;
      }
      .sc4 {
        bottom: 80px;
        left: 20%;
      }
      .sc5 {
        bottom: 106px;
        left: 48%;
      }
      .sc6 {
        bottom: 44px;
        right: 20%;
      }
      .textBox {
        width: 522px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        span {
          font-size: 24px;
          margin-bottom: 56px;
        }
        p {
          font-size: 18px;
          color: #000;
          line-height: 34px;
        }
      }
    }
  }
}

.qualification {
  padding: 60px 50px;
  background-color: #F5FBFF;
  min-height: 500px;
  // .imgBox {
  //   width: 356px;
  //   height: 234px;
  // }

  .imgBox {
      display: flex;
    flex-wrap: wrap;
  .imgs {
    width: 33.33%;
    height: 234px;
    margin-bottom: 20px;
    text-align: center;
    // div {
    //   background-color: #fff;
    //   width: 356px;
    //   height: 234px;
    // }
    img {
      width: 256px;
      height: 234px;
      object-fit: contain;
    }
  }
  }
}

.contactUs {
  padding: 60px 150px;
  .textBox {
    display: flex;
    justify-content: space-between;
    .address,
    .tel {
      display: flex;
      align-items: center;
      .icon {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        border: 1px solid #36AEFF;
        color: #36AEFF;
        margin-right: 10px;
      }
      span {
        color: #000;
      }
    }
  }
  .mapBox {
    width: 100%;
    height: 274px;
    margin-top: 30px;
  }
}
</style>
